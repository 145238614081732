import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Home',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Home',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'ecommerce',
            name: 'Home'
          },
        ]
      },

    ],
  },
  {
    label: 'Cities',
    main: [
      {
        state: 'master-data',
        short_label: 'D',
        name: 'Cities',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'cities',
            name: 'Cities'
          },
        ]
      },

    ],
  },
  {
    label: 'Branches',
    main: [
      {
        state: 'master-data',
        short_label: 'D',
        name: 'Branches',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'branches',
            name: 'Branches'
          },
        ]
      },

    ],
  },
  {
    label: 'Categories',
    main: [
      {
        state: 'master-data',
        short_label: 'D',
        name: 'Categories',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'categories',
            name: 'Categories'
          },
        ]
      },

    ],
  },
  {
    label: 'Products',
    main: [
      {
        state: 'master-data',
        short_label: 'D',
        name: 'Products',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'products',
            name: 'Products'
          },
        ]
      },

    ],
  },
  {
    label: 'Slider',
    main: [
      {
        state: 'slider-info',
        short_label: 'N',
        name: 'Slider',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  {
    label: 'Slider Tow',
    main: [
      {
        state: 'slider2-photo',
        short_label: 'N',
        name: 'Slider Tow',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  {
    label: 'Up To Message',
    main: [
      {
        state: 'up-to-message',
        short_label: 'N',
        name: 'Up To Message',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  {
    label: 'Lists',
    main: [
      {
        state: 'lists',
        short_label: 'N',
        name: 'Lists',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  
  {
    label: 'Admins',
    main: [
      {
        state: 'admins',
        short_label: 'N',
        name: 'Admins',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  {
    label: 'Boxes',
    main: [
      {
        state: 'boxes',
        short_label: 'N',
        name: 'Boxes',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  
  {
    label: 'Locations',
    main: [
      {
        state: 'store-location',
        short_label: 'N',
        name: 'Locations',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  {
    label: 'Orders',
    main: [
      {
        state: 'orders',
        short_label: 'D',
        name: 'Orders',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'all-orders',
            name: 'All Orders'
          },
          {
            state: 'today-orders',
            name: 'Today Orders'
          },
          {
            state:'create-order',
            name:'Create Order'
          }
        ]
      },

    ],
  },
  {
    label: 'Gift Cards',
    main: [
      {
        state: 'gift-card',
        short_label: 'D',
        name: 'Gift Cards',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'gift-categories',
            name: 'Gift Categories'
          },
          {
            state: 'cards',
            name: 'Gift Cards'
          },
          {
            state: 'gift-message-design-categories',
            name: 'Gift Message Design Categories'
          },
          
        ]
      },

    ],
  },
  
  {
    label: 'Reports',
    main: [
      {
        state: 'reports',
        short_label: 'D',
        name: 'Reports',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'category-reports',
            name: 'Category Reports'
          },
          {
            state: 'best-selling-report',
            name: 'Best Selling Report'
          },
          {
            state: 'items-without-sale',
            name: 'Items Without Sale'
          },
          {
            state: 'transaction-by-item',
            name: 'Transaction By Item'
          },
          {
            state: 'transaction-by-customer',
            name: 'Transaction By Customer'
          },
          {
            state: 'transaction-by-store',
            name: 'Transaction By Store'
          },
          {
            state: 'store-anlysis',
            name: 'Store Anlysis'
          },
        ]
      },

    ],
  },
  {
    label: 'Messages',
    main: [
      {
        state: 'user-messages',
        short_label: 'N',
        name: 'Messages',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  {
    label: 'Corporate Gifts',
    main: [
      {
        state: 'corporate-gift',
        short_label: 'N',
        name: 'Corporate Gifts',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  
  {
    label: 'Contact',
    main: [
      {
        state: 'contact',
        short_label: 'N',
        name: 'Contact',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  {
    label: 'Coupon Codes',
    main: [
      {
        state: 'coupon-codes',
        short_label: 'N',
        name: 'Coupon Codes',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  {
    label: 'Shipping Companies',
    main: [
      {
        state: 'shipping-companies',
        short_label: 'N',
        name: 'Shipping Companies',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  {
    label: 'Shipping Companies Supervisors',
    main: [
      {
        state: 'supervisors',
        short_label: 'N',
        name: 'Supervisors',
        type: 'link',
        icon: 'feather icon-menu'
      },
    ],
  },
  {
    label: 'Jobs',
    main: [
      {
        state: 'jobs',
        short_label: 'D',
        name: 'Jobs',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'all-jobs',
            name: 'Jobs'
          },
        ]
      },

    ],
  },
  {
    label: 'Dishes',
    main: [
      {
        state: 'dishes',
        short_label: 'D',
        name: 'Dishes',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'dishes',
            name: 'Dishes'
          },
        ]
      },

    ],
  },
  // {
  //   label: 'Home',
  //   main: [
  //     {
  //       state: 'dashboard',
  //       short_label: 'D',
  //       name: 'Home',
  //       type: 'sub',
  //       icon: 'feather icon-home',
  //       children: [
  //         {
  //           state: 'ecommerce',
  //           name: 'Home'
  //         },
  //       ]
  //     },

  //   ],
  // },
  // {
  //   label: 'Master Data',
  //   main: [
  //     {
  //       state: 'master-data',
  //       short_label: 'B',
  //       name: 'Basic',
  //       type: 'sub',
  //       icon: 'feather icon-box',
  //       children: [
  //         {
  //           state: 'cities',
  //           name: 'Cities'
  //         },
  //         {
  //           state: 'branches',
  //           name: 'Branches'
  //         },
  //         {
  //           state: 'categories',
  //           name: 'Categories'
  //         },
  //         {
  //           state: 'products',
  //           name: 'Products'
  //         },
  //       ]
  //     },
  //   ]
  // },
];
const MENUITEMSForSupervisor = [
  {
    label: 'Home',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Home',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'ecommerce',
            name: 'Home'
          },
        ]
      },

    ],
  },
  {
    label: 'Orders',
    main: [
      {
        state: 'orders',
        short_label: 'D',
        name: 'Orders',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'orders-for-supervisor',
            name: 'All Orders'
          },
        ]
      },

    ],
  },
];
const AdminBranchMENUITEMS = [
  {
    label: 'Home',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Home',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'ecommerce',
            name: 'Home'
          },
        ]
      },

    ],
  },

  {
    label: 'Branches',
    main: [
      {
        state: 'master-data',
        short_label: 'D',
        name: 'Branches',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'branches',
            name: 'Branches'
          },
        ]
      },

    ],
  },

  {
    label: 'Products',
    main: [
      {
        state: 'master-data',
        short_label: 'D',
        name: 'Products',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'products',
            name: 'Products'
          },
        ]
      },

    ],
  },
  {
    label: 'Orders',
    main: [
      {
        state: 'orders',
        short_label: 'D',
        name: 'Orders',
        type: 'sub',
        icon: 'feather icon-home',
        children: [
          {
            state: 'all-orders-for-admin-branch',
            name: 'All Orders'
          },
          {
            state: 'today-orders-for-admin-branch',
            name: 'Today Orders'
          },
        ]
      },

    ],
  },

];
@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
  getAllForSupervisor(): Menu[] {
    return MENUITEMSForSupervisor;
  }
  getAllForAdminBranch(): Menu[] {
    return AdminBranchMENUITEMS;
  }
}
